/* eslint-disable react-hooks/exhaustive-deps */
import { graphql } from "gatsby"
import React, { useCallback, useEffect, useState } from "react"
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
  Table,
} from "reactstrap"
import { CountriesGrid, Hero, Layout, Link, Seo } from "../../components"
import addMethodIcon from "../../images/add-method-icon.svg"
import removeIcon from "../../images/compare-remove.svg"
import closeIcon2 from "../../images/close-icon-2.svg"
import more from "../../images/more.svg"
import downloadMethodIcon from "../../images/download-method-icon.svg"
import {
  addMethodToCompare,
  getClassFromSlug,
  removeFromCompare,
  stripTags,
} from "../../lib/utils"
import ClickAwayListener from "react-click-away-listener"
import CompareTable from "../../components/CompareTable/CompareTable"

const CompareMethods = ({ data, pageContext }) => {
  const lang = data?.PageDetails?.edges[0]?.node?.fmmCore?.languageCode
  const slug = data?.PageDetails?.edges[0]?.node?.fmmCore?.frontendSlug
  const copyrightMessage = stripTags(
    data?.FooterCopyright?.edges[0]?.node?.content
  )

  const [modals, setModals] = useState({
    selectMethodModal: false,
    idealMethodModal: false,
  })
  const { selectMethodModal, idealMethodModal } = modals
  const [isChecked, setIsChecked] = useState({})
  const [mainSelectorOpen, setMainSelectorOpen] = useState(false)

  const [selectedMethodIndex, setSelectedMethodIndex] = useState(null)

  const toggleSelectMethodModal = () =>
    setModals(prevModals => ({
      ...prevModals,
      selectMethodModal: !prevModals.selectMethodModal,
    }))

  const toggleIdealMethodModal = () =>
    setModals(prevModals => ({
      ...prevModals,
      idealMethodModal: !prevModals.idealMethodModal,
    }))

  const toggleMoreOptions = index => {
    setSelectedMethodIndex(selectedMethodIndex === index ? null : index)
    setMainSelectorOpen(true)
  }

  const [compareMethodsData, setCompareMethodsData] = useState([])
  const [compareMethodsId, setCompareMethodsId] = useState([])

  const labelCons =
    data?.MethodTableTranslations?.V2MethodsStringTranslations?.labelCons
  const labelEffectiveness =
    data?.MethodTableTranslations?.V2MethodsStringTranslations
      ?.labelEffectiveness
  const labelPros =
    data?.MethodTableTranslations?.V2MethodsStringTranslations?.labelPros
  const labelWhatIsIt =
    data?.MethodTableTranslations?.V2MethodsStringTranslations?.labelWhatIsIt

  const btnDelete = data?.ControlText?.edges[0]?.node?.title
  const btnMainMethod =  data?.ControlText?.edges[0]?.node?.content

  const idealPopupTimer = useCallback(
    time => {
      setTimeout(() => {
        if (!modals.selectMethodModal) {
          toggleIdealMethodModal()
        }
      }, time)
    },
    [modals.selectMethodModal]
  )

  useEffect(() => {
    setCompareMethodsData(
      JSON.parse(sessionStorage?.getItem(`compareMethods-${lang}`)) || []
    )
  }, [])

  const toggleMethod = method => {
    if (isChecked[method.node.id]) {
      removeFromCompare({ method, methodId: method?.node?.id }, lang)
    } else {
      addMethodToCompare({ method, methodId: method?.node?.id }, lang)
      idealPopupTimer(90000)
      // toggleSelectMethodModal()
    }

    setCompareMethodsData(
      JSON.parse(sessionStorage?.getItem(`compareMethods-${lang}`)) || []
    )
  }

  const handleCheckboxChange = method => {
    setIsChecked(prevState => ({
      ...prevState,
      [method.node.id]: !prevState[method.node.id],
    }))

    toggleMethod(method)
  }

  const handleChooseAsMain = method => {
    const methodIndex = compareMethodsData.findIndex(
      item => item.method.node.id === method.node.id
    )

    if (methodIndex !== -1) {
      const updatedCompareMethodsData = [
        compareMethodsData[methodIndex],
        ...compareMethodsData.slice(0, methodIndex),
        ...compareMethodsData.slice(methodIndex + 1),
      ]

      setCompareMethodsData(updatedCompareMethodsData)
    }
  }

  const removeItem = method => {
    removeFromCompare({ method, methodId: method?.node?.id }, lang)

    setCompareMethodsData(
      JSON.parse(sessionStorage?.getItem(`compareMethods-${lang}`))
    )
  }

  // Remove absolute @Todo
  const addItem = method => {
    addMethodToCompare({ method, methodId: method?.node?.id }, lang)

    setCompareMethodsData(
      JSON.parse(sessionStorage?.getItem(`compareMethods-${lang}`))
    )
    toggleSelectMethodModal()
    idealPopupTimer(90000)
  }

  useEffect(() => {
    const ids = []

    compareMethodsData.forEach(item => ids.push(item?.methodId))
    setCompareMethodsId(ids)
  }, [compareMethodsData])

  useEffect(() => {
    const initialCheckedState = {}
    compareMethodsData.forEach(item => {
      initialCheckedState[item.methodId] = true
    })
    setIsChecked(initialCheckedState)
  }, [compareMethodsData])

  return (
    <Layout
      lang={lang}
      languages={data?.Languages?.edges}
      copyrightMessage={copyrightMessage}
      slug={slug}
    >
      <Seo
        title={data?.PageDetails?.edges[0]?.node?.fmmCore?.seoTitle}
        description={data?.PageDetails?.edges[0]?.node?.fmmCore?.seoDescription}
        lang={lang}
      />

      <Hero
        title={data?.PageDetails?.edges[0]?.node?.hero?.title}
        intro={data?.PageDetails?.edges[0]?.node?.hero?.intro}
        linkTitle={data?.PageDetails?.edges[0]?.node?.hero?.linkLabel}
        linkUrl={data?.PageDetails?.edges[0]?.node?.hero?.linkUrl}
        imageUrl={data?.PageDetails?.edges[0]?.node?.hero?.image?.sourceUrl}
        slug={getClassFromSlug(
          data?.PageDetails?.edges[0]?.node?.fmmCore?.frontendSlug
        )}
        lang={lang}
        pageContext={pageContext}
      />

      <section className="page-section">
        <Container>
          <Row className="mb-5">
            <section className="col-md-9 col-12 mb-2">
              <h2 className="m-0">
                {data?.CompareMethodsTitle?.edges[0]?.node?.title}
              </h2>
            </section>
            <section className="col-md-7 col-12">
              <section
                style={{ fontSize: "25px" }}
                className="mb-5"
                dangerouslySetInnerHTML={{
                  __html: data?.CompareMethodsTitle?.edges[0]?.node?.content,
                }}
              >
                {/* Are you wondering if condoms are better than daily pills? Or if
                you should opt for a birth control implant? We're here to assist
                you in making this decision. Select up to 5 contraceptive
                methods and compare them side by side to weigh the pros and cons
                of each. */}
              </section>
            </section>
          </Row>
          <Row>
            <Col xs="12">
              <div className="compare-table__holder">
                <Table responsive borderless className="compare-table">
                  <tbody>
                    <tr className="compare-table__row">
                      {compareMethodsData?.map((data, index) => {
                        return (
                          <CompareTable
                            key={index}
                            imageUrl={
                              data?.method?.node?.V2Methods?.image?.sourceUrl
                            }
                            index={index}
                            toggleMoreOptions={toggleMoreOptions}
                            selectedMethodIndex={selectedMethodIndex}
                            setSelectedMethodIndex={setSelectedMethodIndex}
                            setMainSelectorOpen={setMainSelectorOpen}
                            handleCheckboxChange={handleCheckboxChange}
                            btnDelete={btnDelete}
                            btnMainMethod={btnMainMethod}
                            method={data?.method}
                            handleChooseAsMain={handleChooseAsMain}
                            methodLink={
                              data?.method?.node?.V2Methods?.frontendSlug
                            }
                            lang={lang}
                            methodTitle={data?.method?.node?.V2Methods?.name}
                            methodType={data?.method?.node?.V2Methods?.methodTranslation}
                            whatIsIt={
                              data?.method?.node?.V2Methods?.shortDescription
                            }
                            effectiveness={
                              data?.method?.node?.V2Methods?.effectiveness
                            }
                            pros={data?.method?.node?.V2Methods?.pros}
                            cons={data?.method?.node?.V2Methods?.cons}
                            labelCons={labelCons}
                            labelEffectiveness={labelEffectiveness}
                            labelPros={labelPros}
                            labelWhatIsIt={labelWhatIsIt}
                          />
                        )
                      })}
                    </tr>
                  </tbody>
                </Table>
              </div>

              <section className="page-section pb-0">
                {compareMethodsData && compareMethodsData?.length < 6 && (
                  <Button
                    className="compare-buttons"
                    onClick={toggleSelectMethodModal}
                  >
                    <img
                      src={addMethodIcon}
                      alt=""
                      height="20px"
                      width="20px"
                      loading="lazy"
                    />
                    {data?.CompareAddPopupTrigger?.edges[0]?.node?.title}
                  </Button>
                )}

                {!compareMethodsData.length && (
                  <section className="page-section pb-0">
                    <section className="compare-method__empty">
                      <img
                        src={
                          data?.PageDetails?.edges[0]?.node?.hero?.image
                            ?.sourceUrl
                        }
                        alt="image"
                      />
                      <p>
                      {data?.TableBackMessage?.edges[0]?.node?.title}
                      </p>
                    </section>
                  </section>
                )}

                <Button
                  className="compare-buttons"
                  onClick={() => window.print()}
                >
                  <img
                    src={downloadMethodIcon}
                    alt=""
                    height="20px"
                    width="20px"
                    loading="lazy"
                  />
                  {data?.CompareDownloadPdf?.edges[0]?.node?.title}
                </Button>
              </section>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal
        isOpen={selectMethodModal}
        toggle={toggleSelectMethodModal}
        className="select-method-popup modal-dialog-scrollable"
      >
        <ModalBody>
          <Container fluid>
            <button onClick={toggleSelectMethodModal} className="close-popup">
              <img src={closeIcon2} alt="" />
            </button>
            <Row>
              <Col xs="12">
                <h4 className="m-0">
                  {data?.CompareAddMethod?.edges[0]?.node?.title}
                </h4>
                <section
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: data?.CompareAddMethod?.edges[0]?.node?.content,
                  }}
                />
              </Col>
            </Row>

            <Row className="select-method-popup__methods-list">
              {data?.Methods?.edges?.map((method, index) => (
                <Col
                  xs="12"
                  sm="6"
                  md="4"
                  lg="3"
                  className="select-method-popup__methods-list-item"
                  key={index}
                >
                  <figure className="compare-popup__image-holder">
                    <img
                      src={method?.node?.V2Methods?.image?.sourceUrl}
                      alt=""
                      width="250px"
                      loading="lazy"
                    />
                  </figure>
                  <p className="mb-0">{method?.node?.V2Methods?.name}</p>
                  <span className="mb-3">{method?.node?.V2Methods?.methodTranslation}</span>

                  <label>
                    <input
                      type="checkbox"
                      checked={isChecked[method.node.id] || false}
                      onChange={() => handleCheckboxChange(method)}
                      disabled={
                        compareMethodsData.length >= 5 &&
                        !isChecked[method.node.id]
                      }
                    />
                    <span className="ml-2">{method?.node?.V2Methods?.labelAddmethod}</span>
                  </label>
                </Col>
              ))}
            </Row>

            <Row className="d-flex justify-content-end">
              <button
                className="button btn_disabled"
                onClick={() => toggleSelectMethodModal()}
                disabled={!compareMethodsData.length}
              >
                <span>{data?.BtnAddMethod?.edges[0]?.node?.title}</span>
              </button>
            </Row>
          </Container>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={idealMethodModal}
        toggle={toggleIdealMethodModal}
        className="ideal-method-popup modal-dialog-scrollable"
      >
        <ModalBody>
          <Container fluid>
            <button onClick={toggleIdealMethodModal} className="close-popup">
              <img src={closeIcon2} alt="" />
            </button>
            <Row>
              <Col xs={12}>
                <h3 className="h4 mb-0">
                  {data?.CompareIdealMethodPopupTitle?.edges[0]?.node?.title}
                </h3>
              </Col>
            </Row>

            <Row className="pt-5">
              <Col xs={12}>
                <h4 className="mb-2">
                  {data?.CompareIdealMethodPopupIfSo?.edges[0]?.node?.title}
                </h4>
                <section
                  dangerouslySetInnerHTML={{
                    __html:
                      data?.CompareIdealMethodPopupIfSo?.edges[0]?.node
                        ?.content,
                  }}
                />
              </Col>

              <Col xs={12}>
                <CountriesGrid lang={lang} />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <h4 className="mb-2">
                  {
                    data?.CompareIdealMethodPopupCantFindCountry?.edges[0]?.node
                      ?.title
                  }
                </h4>

                <section
                  dangerouslySetInnerHTML={{
                    __html:
                      data?.CompareIdealMethodPopupCantFindCountry?.edges[0]
                        ?.node?.content,
                  }}
                />

                <section className="page-section pb-0 text-center">
                  <Button
                    className="button"
                    onClick={() => {
                      toggleIdealMethodModal()
                      toggleSelectMethodModal()
                    }}
                  >
                    {
                      data?.CompareIdealMethodPopupCantFindCountry?.edges[0]
                        ?.node?.generic_block_data.linkLabel
                    }
                  </Button>
                </section>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </Layout>
  )
}

export default CompareMethods

export const query = graphql`
  query ($frontend_slug: String!, $language_code: String!) {
    PageDetails: allWpPage(
      filter: {
        fmmCore: {
          languageCode: { eq: $language_code }
          frontendSlug: { eq: $frontend_slug }
        }
      }
    ) {
      edges {
        node {
          id
          fmmCore {
            languageCode
            frontendSlug
            seoDescription
            seoTitle
          }
          hero {
            title
            linkUrl
            linkLabel
            intro
            image {
              sourceUrl
            }
          }
        }
      }
    }

    Languages: allWpPage(
      filter: { fmmCore: { frontendSlug: { eq: $frontend_slug } } }
    ) {
      edges {
        node {
          id
          fmmCore {
            languageCode
            frontendSlug
          }
        }
      }
    }

    CompareMethodsTitle: allWpGenericBlock(
      filter: {
        fmmCore: {
          languageCode: { eq: $language_code }
          frontendSlug: { eq: $frontend_slug }
        }
        generic_block_data: { type: { eq: "CompareMethodsTitle" } }
      }
    ) {
      edges {
        node {
          title
          content
        }
      }
    }

    Methods: allWpV2Method(
      filter: { V2Methods: { languageCode: { eq: $language_code } } }
      sort: { fields: V2Methods___orderId, order: ASC }
    ) {
      edges {
        node {
          id
          slug
          title
          V2Methods {
            languageCode
            name
            shortDescription
            frontendSlug
            methodCriteria
            type
            methodTranslation
            effectiveness {
              efectiveField
            }
            cons 
            pros 
            image {
              sourceUrl
            }
            labelAddmethod
          }
        }
      }
    }

    CompareMethodsPopupTitle: allWpGenericBlock(
      filter: {
        fmmCore: {
          languageCode: { eq: $language_code }
          frontendSlug: { eq: "/birth-control-options" }
        }
        generic_block_data: {
          type: { eq: "BirthControlPreferenceSectionTitle" }
        }
      }
    ) {
      edges {
        node {
          title
          content
        }
      }
    }

    CompareTableHeader: allWpGenericBlock(
      sort: { fields: generic_block_data___priorityOrder }
      filter: {
        fmmCore: {
          languageCode: { eq: $language_code }
          frontendSlug: { eq: $frontend_slug }
        }
        generic_block_data: { type: { eq: "CompareTableHeader" } }
      }
    ) {
      edges {
        node {
          title
          content
        }
      }
    }

    CompareAddPopupTrigger: allWpGenericBlock(
      sort: { fields: generic_block_data___priorityOrder }
      filter: {
        fmmCore: {
          languageCode: { eq: $language_code }
          frontendSlug: { eq: $frontend_slug }
        }
        generic_block_data: { type: { eq: "CompareAddPopupTrigger" } }
      }
    ) {
      edges {
        node {
          title
        }
      }
    }

    CompareDownloadPdf: allWpGenericBlock(
      sort: { fields: generic_block_data___priorityOrder }
      filter: {
        fmmCore: {
          languageCode: { eq: $language_code }
          frontendSlug: { eq: $frontend_slug }
        }
        generic_block_data: { type: { eq: "CompareDownloadPdf" } }
      }
    ) {
      edges {
        node {
          title
        }
      }
    }

    CompareAddMethod: allWpGenericBlock(
      sort: { fields: generic_block_data___priorityOrder }
      filter: {
        fmmCore: {
          languageCode: { eq: $language_code }
          frontendSlug: { eq: $frontend_slug }
        }
        generic_block_data: { type: { eq: "CompareAddMethod" } }
      }
    ) {
      edges {
        node {
          title
          content
          generic_block_data {
            linkLabel
          }
        }
      }
    }

    MethodTableTranslations: wpV2Method(
      V2Methods: { languageCode: { eq: $language_code } }
    ) {
      V2MethodsStringTranslations {
        labelCons
        labelWhatIsIt
        labelPros
        labelEffectiveness
      }
    }

    FooterCopyright: allWpGenericBlock(
      filter: {
        fmmCore: { languageCode: { eq: $language_code } }
        generic_block_data: { type: { eq: "FooterCopyright" } }
      }
    ) {
      edges {
        node {
          title
          content
        }
      }
    }

    CompareIdealMethodPopupTitle: allWpGenericBlock(
      sort: { fields: generic_block_data___priorityOrder }
      filter: {
        fmmCore: {
          languageCode: { eq: $language_code }
          frontendSlug: { eq: $frontend_slug }
        }
        generic_block_data: { type: { eq: "CompareIdealMethodPopupTitle" } }
      }
    ) {
      edges {
        node {
          title
        }
      }
    }

    CompareIdealMethodPopupIfSo: allWpGenericBlock(
      sort: { fields: generic_block_data___priorityOrder }
      filter: {
        fmmCore: {
          languageCode: { eq: $language_code }
          frontendSlug: { eq: $frontend_slug }
        }
        generic_block_data: { type: { eq: "CompareIdealMethodPopupIfSo" } }
      }
    ) {
      edges {
        node {
          title
          content
        }
      }
    }
    BtnAddMethod: allWpGenericBlock(
      filter: {
        fmmCore: { languageCode: { eq: $language_code } }
        generic_block_data: { type: { eq: "BtnAddMethod" } }
      }
    ) {
      edges {
        node {
          title
        }
      }
    }

    ControlText: allWpGenericBlock(
      filter: {
        fmmCore: { languageCode: { eq: $language_code } }
        generic_block_data: { type: { eq: "ControlText" } }
      }
    ) {
      edges {
        node {
          title
          content
        }
      }
    }
    
    TableBackMessage: allWpGenericBlock(
      filter: {
        fmmCore: { languageCode: { eq: $language_code } }
        generic_block_data: { type: { eq: "TableBackMessage" } }
      }
    ) {
      edges {
        node {
          title
        }
      }
    }	

    CompareIdealMethodPopupCantFindCountry: allWpGenericBlock(
      sort: { fields: generic_block_data___priorityOrder }
      filter: {
        fmmCore: {
          languageCode: { eq: $language_code }
          frontendSlug: { eq: $frontend_slug }
        }
        generic_block_data: {
          type: { eq: "CompareIdealMethodPopupCantFindCountry" }
        }
      }
    ) {
      edges {
        node {
          title
          content
          generic_block_data {
            linkLabel
          }
        }
      }
    }
  }
`